import React, { useState } from "react";
import SidebarInside from "../../sidebar-inside/Sidebar-inside";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import AddKm from "./components/addkm/addkm";
import { userServerResponse } from "../mocks/Users";
import UsersPage from "../common/Users";
import Stats from "./Stats";
import ShopEditUser from "./components/ShopEditUser";
import Products from "./components/products/Products";
import ProductEditLayout from "./components/products/components/ProductEditLayout/ProductEditLayout";
import "./Products.css";

const Shop = () => {
  let routes = [
    {
      id: "1",
      title: "Lista przejazdów",
      path: "dodane",
      cName: "sidebar-inside-btns",
      page: "Kilo",
    },
    {
      id: "2",
      title: "Dodaj przejazd",
      path: "dodawanie",
      cName: "sidebar-inside-btns",
      page: "Organizations",
    },
  ];
  routes = [...routes];

  const [pageContentSwitch, setPageContentSwitch] = useState("1");

  const [usersData, setUserData] = useState(userServerResponse);
  return (
    <>
      <SidebarInside setPageContentSwitch={setPageContentSwitch} currentPath="/kilometry/" routes={routes} />

      <div className="page-inside-content">
        <Switch>
          <Route exact path="/kilometry/dodane" component={Products} />
          <Route exact path="/kilometry/dodawanie" component={AddKm} />
          <Route exact path="/kilometry/uzytkownicy" render={(props) => <UsersPage users={usersData} {...props} />} />
          <Route exact path="/kilometry/statystyki" component={Stats} />
          <Route path="/kilometry/produkty/:id" component={ProductEditLayout} />
          <Route exact path="/kilometry/edytuj/:id" component={ShopEditUser} />
        </Switch>
      </div>
    </>
  );
};

export default Shop;
