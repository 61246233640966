import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx"; // Import biblioteki XLSX

function TotalKilometers() {
  const [totalKm, setTotalKm] = useState(0);
  const [products, setProducts] = useState([]); // Stan do przechowywania produktów
  const [error, setError] = useState(null);

  const fetchKilometers = async () => {
    try {
      const token = localStorage.getItem("token"); // Pobieranie tokenu z localStorage
      const userId = localStorage.getItem("userId"); // Pobieranie userId z localStorage
      const response = await axios.get("http://192.168.1.7:5000/api/products", {
        headers: {
          Authorization: `Bearer ${token}`, // Dodawanie tokenu do nagłówka
        },
      });
      const data = response.data;

      // Filtrowanie produktów według userId
      const userProducts = data.filter((item) => item.userId === userId); // Dostosuj nazwę właściwości w razie potrzeby

      // Sumowanie kilometrów dla przefiltrowanych produktów
      const sum = userProducts.reduce((acc, item) => acc + item.km, 0);
      setTotalKm(sum);
      setProducts(userProducts); // Przechowywanie przefiltrowanych produktów w stanie
    } catch (error) {
      console.error("Błąd podczas pobierania kilometrów:", error);
      setError("Wystąpił błąd podczas pobierania kilometrów.");
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(products); // Konwertowanie danych do arkusza
    const workbook = XLSX.utils.book_new(); // Tworzenie nowego pliku Excel
    XLSX.utils.book_append_sheet(workbook, worksheet, "Produkty"); // Dodawanie arkusza do pliku

    // Zapisywanie pliku Excel
    XLSX.writeFile(workbook, "produkty.xlsx");
  };

  useEffect(() => {
    fetchKilometers();
  }, []);

  return (
    <div className="form-container">
      <h2>Łączna suma kilometrów</h2>
      {error && <p className="error-message">{error}</p>}
      <h4>Twoja suma to: {totalKm} km</h4>
      <button onClick={exportToExcel} className="export-button">
        Eksportuj do Excela
      </button>
    </div>
  );
}

export default TotalKilometers;
