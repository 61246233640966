import React from "react";
import { useParams, Link } from "react-router-dom";

const ShopEditUser = () => {
  const params = useParams();
  return (
    <div>
      <Link to="../uzytkownicy">Powrót</Link>
      <div>klikłeś se użytnika o id: {params.id} </div>
    </div>
  );
};

export default ShopEditUser;
