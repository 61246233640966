import React, { useState } from "react";
import axios from "axios";
import "./addkm.css";

function Addkm() {
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const [kilometers, setKilometers] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");

  const addProduct = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      setMessage("Brak tokenu autoryzacyjnego. Zaloguj się ponownie.");
      return;
    }

    const newProduct = {
      name: description,
      km: parseFloat(kilometers), // Upewnij się, że kilometry są w formacie liczby
      date: date,
    };

    try {
      const response = await axios.post("http://192.168.1.7:5000/api/products", newProduct, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        setMessage("Kilometry zostały dodane pomyślnie!");
        // Czyści formularz po dodaniu produktu
        setKilometers("");
        setDescription("");
      } else {
        setMessage("Wystąpił błąd podczas dodawania kilometrów.");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      setMessage("Wystąpił błąd: " + (error.response?.data?.error || error.message));
    }
  };

  return (
    <form className="form-container" onSubmit={addProduct}>
      <h2>Dodaj kilometry</h2>
      <label>
        Dzień
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
      </label>
      <label>
        Kilometry
        <input type="number" step="0.1" placeholder="0.0" value={kilometers} onChange={(e) => setKilometers(e.target.value)} required />
      </label>
      <label>
        Opis
        <input type="text" placeholder="Dodaj opis podróży..." value={description} onChange={(e) => setDescription(e.target.value)} required />
      </label>
      <button type="submit">Dodaj kilometry</button>
      {message && <p className="message">{message}</p>}
    </form>
  );
}

export default Addkm;
