import React, { useEffect, useState } from "react";
import axios from "axios";

function KilometersSummary() {
  const [dailyKm, setDailyKm] = useState({});
  const [weeklyKm, setWeeklyKm] = useState({});
  const [monthlyKm, setMonthlyKm] = useState({});
  const [error, setError] = useState(null);

  const fetchKilometers = async () => {
    try {
      const response = await axios.get("http://192.168.1.7:5000/api/products");
      const data = response.data;

      // Obliczanie kilometrów dziennych
      const dailyTotals = {};
      const weeklyTotals = {};
      const monthlyTotals = {};

      data.forEach((item) => {
        const date = new Date(item.date);
        const day = date.toISOString().split("T")[0]; // Dzień w formacie yyyy-mm-dd
        const week = `${date.getFullYear()}-W${getISOWeek(date)}`; // Tydzień
        const month = `${date.getFullYear()}-${date.getMonth() + 1}`; // Miesiąc

        dailyTotals[day] = (dailyTotals[day] || 0) + item.km; // Sumowanie dziennych kilometrów
        weeklyTotals[week] = (weeklyTotals[week] || 0) + item.km; // Sumowanie tygodniowych kilometrów
        monthlyTotals[month] = (monthlyTotals[month] || 0) + item.km; // Sumowanie miesięcznych kilometrów
      });

      setDailyKm(dailyTotals);
      setWeeklyKm(weeklyTotals);
      setMonthlyKm(monthlyTotals);
    } catch (error) {
      console.error("Error fetching kilometers:", error);
      setError("Wystąpił błąd podczas pobierania kilometrów.");
    }
  };

  const getISOWeek = (date) => {
    const target = new Date(date.valueOf());
    const dayNr = (date.getUTCDay() + 6) % 7; // Poniedziałek jako pierwszy dzień tygodnia
    target.setUTCDate(target.getUTCDate() - dayNr + 3); // Ustaw na 3 dni w tygodniu
    const firstThursday = target.valueOf();
    target.setUTCDate(target.getUTCDate() + 4); // Ustaw na następny czwartek
    return Math.floor((target.valueOf() - firstThursday) / 604800000) + 1; // Zwróć tydzień
  };

  useEffect(() => {
    fetchKilometers();
  }, []);

  return (
    <div className="form-container">
      <h2>Podsumowanie kilometrów</h2>
      {error && <p className="error-message">{error}</p>}

      <h3>Dziennie</h3>
      <ul>
        {Object.entries(dailyKm).map(([date, km]) => (
          <li key={date}>
            {date}: {km} km
          </li>
        ))}
      </ul>

      <h3>Tygodniowo</h3>
      <ul>
        {Object.entries(weeklyKm).map(([week, km]) => (
          <li key={week}>
            {week}: {km} km
          </li>
        ))}
      </ul>

      <h3>Miesięcznie</h3>
      <ul>
        {Object.entries(monthlyKm).map(([month, km]) => (
          <li key={month}>
            {month}: {km} km
          </li>
        ))}
      </ul>
    </div>
  );
}

export default KilometersSummary;
