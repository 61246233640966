import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import './loading.css'

const Loading = (props) => {
    return (
        <div className="loading-screen">
            <ClipLoader size={72} color={"#000"} loading={props.loading} />
            <span className="loading-text">Ładowanie</span>
        </div>
    )
}

export default Loading
    