import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Products.css";

const ShopProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("http://192.168.1.7:5000/api/products", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const deleteProduct = async (id) => {
    const confirmDelete = window.confirm("Czy na pewno chcesz usunąć ten wpis?");
    if (confirmDelete) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.delete(`http://192.168.1.7:5000/api/products/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setProducts(products.filter((product) => product._id !== id));
        } else {
          alert("Nie udało się usunąć produktu.");
        }
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("Wystąpił błąd przy usuwaniu produktu.");
      }
    }
  };

  return (
    <div className="shop-products">
      <h2 className="shop-title">Przejechane kilometry</h2>
      {products.length === 0 ? (
        <p>Brak dodanych tras!</p>
      ) : (
        <table className="products-table">
          <thead>
            <tr>
              <th>Data trasy</th>
              <th>Ilość kilometrów</th>
              <th>Opis</th>
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {products.map((item) => (
              <tr key={item._id}>
                <td>{item.date}</td>
                <td>{item.km} km</td>
                <td>{item.name}</td>
                <td>
                  <button className="delete-button" onClick={() => deleteProduct(item._id)}>
                    Usuń
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ShopProducts;
