import React from "react";
import ChangePass from "./change-pass/ChangePass";

const Settings = () => {
  return (
    <div className="page-inside-content">
      <div className="form-container">
        <ChangePass />
      </div>
    </div>
  );
};

export default Settings;
