import React from "react";
import Logo from "../../media/img/logo-kms.png";

const SidebarLogo = () => {
  return (
    <div className="sidebar-logo-container">
      <div className="sidebar-logo">
        <img src={Logo} alt="MMS-Logo" className="sidebar-logo-img" />
      </div>
    </div>
  );
};

export default SidebarLogo;
