import React from 'react'
import './forgot-password.css'

const ForgotPasword = (props) => {

    const { switchContent, setSwitchContent } = props;

    return (
    <>
        <form className="l-form" id="forgot-form">

        <h4 className="l-text">Zapomniałeś hasła?</h4>

        <input type="text" name="login" id="nick" value="test@gmail.com" className="l-input" placeholder="Login: " required/>
        <div className="email-error"></div>

        <input type="submit" className="s-input" value="Wyślij prośbe" />
        <p className="l-container-btn"><a href="/#" className="l-btn" onClick={() => setSwitchContent(!switchContent)}>Pamiętasz hasło?</a></p>

        </form>
    </>
    )
}

export default ForgotPasword
