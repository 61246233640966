import React, { useState } from "react";
import SidebarInside from "../../sidebar-inside/Sidebar-inside";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import CommonRoutes from "../common/CommonRoutes";
import { userServerResponse } from "../mocks/Users";
import UsersPage from "../common/Users";
import Stats from "./Stats";
import AllKm from "./components/AllKm";
import WorktimeEditUser from "./components/WorktimeEditUser";
import Monthly from "./components/Monthly";
import "./StatsPage.css";

const StatsPage = () => {
  let routes = [
    {
      id: "1",
      title: "Wszystkie",
      path: "wszystkie",
      cName: "sidebar-inside-btns",
      page: "Workhours",
    },
    {
      id: "2",
      title: "Miesięczne",
      path: "miesiac",
      cName: "sidebar-inside-btns",
      page: "Workhours",
    },
    {
      id: "3",
      title: "Tygodniowe",
      path: "tydzien",
      cName: "sidebar-inside-btns",
      page: "Workhours",
    },
    {
      id: "4",
      title: "Dzienne",
      path: "dzien",
      cName: "sidebar-inside-btns",
      page: "Workhours",
    },
  ];
  routes = [...routes];

  const [pageContentSwitch, setPageContentSwitch] = useState("1");

  const [usersData, setUserData] = useState(userServerResponse);
  return (
    <>
      <SidebarInside setPageContentSwitch={setPageContentSwitch} currentPath="/statystyki/" routes={routes} />

      <div className="page-inside-content">
        <Switch>
          <Route exact path="/statystyki/wszystkie" component={AllKm} />
          <Route exact path="/statystyki/miesiac" component={Monthly} />
          <Route exact path="/statystyki/uzytkownicy" render={(props) => <UsersPage users={usersData} {...props} />} />
          <Route exact path="/statystyki/statystyki" component={Stats} />
          <Route exact path="/statystyki/edytuj/:id" component={WorktimeEditUser} />
        </Switch>
      </div>
    </>
  );
};

export default StatsPage;
