import React, { useEffect, useState } from "react";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Login from "./components/login/login";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import "./css/main.css";
import Loading from "./components/loading/Loading";

const App = () => {
  const [isLogged, setIsLogged] = useState(false); // Ustaw domyślnie false (bo użytkownik nie jest zalogowany na początku)
  const [user, setUser] = useState(""); // Początkowo bez użytkownika
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLogged(true); // Użytkownik jest zalogowany
    } else {
      setIsLogged(false); // Użytkownik jest wylogowany
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      <Router>
        {isLogged ? ( // Kiedy użytkownik jest zalogowany, wyświetlaj Sidebar i resztę aplikacji
          loading ? (
            <Loading loading={loading} />
          ) : (
            <div className="main-container">
              <Sidebar setIsLogged={setIsLogged} setLoading={setLoading} user={user} setUser={setUser} />
              <div className="right-container">
                <Navbar setIsLogged={setIsLogged} />
                <div className="page-content">
                  <Routes />
                </div>
              </div>
            </div>
          )
        ) : (
          // Kiedy użytkownik nie jest zalogowany, wyświetl ekran logowania
          <Login isLogged={isLogged} setIsLogged={setIsLogged} setUser={setUser} />
        )}
      </Router>
    </div>
  );
};

export default App;
