import React from 'react'
import './EditShowcase.css'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';

const EditShowcase = () => {
    const params = useParams();
    const [files, setFiles] = useState({
        "test": {
        content: 'content'
    },
        "test3": {
            content: 'uwu'
        }
    });
    let size = 3;
    let startValue = '';
    const [file, setFile] = useState('test');
    const [textarea, setTextarea] = useState(startValue);
    useEffect(() => {
        setTextarea(files[file].content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file])

    const saveFiles = () => {
        files[file].content = textarea;
        setFiles({...files});
    }

    return (
        <div className="edit-input-wrapper">
            <div className="product-edit-input">
                <textarea className="edit-product-textarea" value={textarea} onChange={(input) => setTextarea(input.target.value)} />
            </div>
            <div className="file-chooser">
              <select defaultValue="test" name="file" size={size} className="select-file" onChange={(input) => setFile(input.target.value)}>
                    <option value="test">test</option>
                    <option value="test3">test3</option>
                </select>
            <button className="btn-save" onClick={saveFiles}>zapisz</button>
            </div>
            
        </div>
    )
}

export default EditShowcase
