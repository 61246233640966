import React, { useState } from "react";
import axios from "axios";

const ChangePass = () => {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Pobieranie tokenu z localStorage

    try {
      const response = await axios.put(
        "http://192.168.1.7:5000/change-password",
        { newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Dodawanie tokenu do nagłówka
          },
        }
      );
      setSuccess(response.data.message);
      setNewPassword(""); // Czyści pole hasła
      setError(null);
    } catch (error) {
      console.error("Error changing password:", error);
      setError("Wystąpił błąd podczas zmiany hasła.");
      setSuccess(null);
    }
  };

  return (
    <div className="change-password-container ">
      <h2>Zmiana hasła</h2>
      <form onSubmit={handleChangePassword}>
        <input type="password" minLength={6} className="change-password-input" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Nowe hasło" required />
        <button type="submit">Zmień hasło</button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
    </div>
  );
};

export default ChangePass;
