import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "./components/pages/home/Home";
import StatsPage from "./components/pages/stats/StatsPage";
import Settings from "./components/pages/settings/Settings";
import Shop from "./components/pages/kilometers/Shop";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/kilometry/*" component={Shop} />
      <Route path="/statystyki/*" component={StatsPage} />
      <Route path="/ustawienia/*" component={Settings} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
