import React, { useState } from 'react'
import './checkbox.css'

const Checkbox = (props) => {

    const [isCheckboxIconOpen, setIsCheckboxIconOpen] = useState(true);

    return (
        <>
            <span className="checkbox-icon" >
                <input type="checkbox" id="rememberMe" className="checkbox" name="rememberMe" autoComplete="off" />
                {isCheckboxIconOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="checkbox-icon-svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                        <path fill="none" stroke="currentColor" strokeWidth="3" d="M1.73 12.91l6.37 6.37L22.79 4.59"></path>
                    </svg>
                ) : null }
            </span>
        </>
    )
}

export default Checkbox
