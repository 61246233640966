import React, { useState, useEffect } from "react";

const HelloUser = (props) => {
  const [username, setUsername] = useState("");

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  return (
    <div className="sidebar-acc-container">
      <div className="sidebar-username-container">
        <p className="sidebar-username">
          Witaj, <span className="sidebar-username-item">{username}</span>
        </p>
      </div>
    </div>
  );
};

export default HelloUser;
