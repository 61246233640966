import React from "react";
import StatisticsLineChart from "../common/components/StatisticsLineChart.jsx";

const Stats = () => {
  const chartLabel = "Przejechane kilometry";
  const data = [353, 0, 0, 0, 0];
  const labels = ["2024-10", "2024-11", "2024-12", "2025-01", "2025-02"];
  return (
    <div>
      <StatisticsLineChart chartLabel={chartLabel} data={data} labels={labels} />
    </div>
  );
};

export default Stats;
