import React, { useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { User } from "react-feather";

const Navbar = (props) => {
  const [isNavbarMenuOpen, setIsNavbarMenuOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token"); // Usunięcie tokenu JWT
    window.location.reload();
  };

  return (
    <div className="navbar">
      <div className="navbar-container">
        <div></div>
        <div className="navbar-menu">
          <div className="navbar-button" onClick={() => setIsNavbarMenuOpen(!isNavbarMenuOpen)}>
            <User size={26} color="#3c3c3c" />
          </div>

          {isNavbarMenuOpen ? (
            <nav>
              <ul className="navbar-items">
                <li>
                  <Link to="/" onClick={handleLogout} className="navbar-items-a">
                    Wyloguj sie
                  </Link>
                </li>
              </ul>
            </nav>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
