// Dobra to chyba jest źle exportowane, nie wiem czemu ale trzeba do tych danych odwoływać się jako do "users.users"
export const userServerResponse = [{
    id: '1',
    user: 'Adrian',
    email: 'test@gmail.com',
    test: 'h11ome-btns'
},
{
    id: '2',
    user: 'Adrian11',
    email: 'test@gmail12.com',
    test: 'home-b11tns'
},
{
    id: '3',
    user: 'Adrian',
    email: 'test@gmail.com',
    test: 'h11ome-btns'
},
{
    id: '4',
    user: 'Adrian11',
    email: 'test@gmail12.com',
    test: 'home-b11tns'
},
{
    id: '5',
    user: 'Adrian',
    email: 'test@gmail.com',
    test: 'h11ome-btns'
},
{
    id: '6',
    user: 'Adrian11',
    email: 'test@gmail12.com',
    test: 'home-b11tns'
}];

