import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import EditBuyMethods from '../EditBuyMethods/EditBuyMethods';
import EditShowcase from '../EditShowcase/EditShowcase';

const ProductEditLayout = () => {
    const params = useParams();
    const path = '/sklep/produkty/';
    const fullpath = path + params.id;
    return (
        <div className="edit-wrapper">
            <div className="edit-menu">
                <Link to={path} className="btn-back">powrut</Link>
                <Link to={fullpath + '/showcase'}>showcase</Link>
                <Link to={fullpath + '/buymethods'}>jakaś inna edycja</Link>
                <Link to={fullpath + '/showcase'}>inna</Link>
                
            </div>
            <div className="edit-wrapper">
                    <Switch>
                        <Route exact path={fullpath + '/showcase'} component={EditShowcase} />
                        <Route exact path={fullpath + '/buymethods'} component={EditBuyMethods} />
                    </Switch>
                </div>
        </div>
    )

}

export default ProductEditLayout;