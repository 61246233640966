import React, { useEffect, useState } from "react";
import axios from "axios";
import StatisticsLineChart from "../common/components/StatisticsLineChart.jsx";

const Stats = () => {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const chartLabel = "Przejechane kilometry";

  const fetchKilometers = async () => {
    try {
      const response = await axios.get("http://192.168.1.7:5000/api/products");
      const kilometersData = response.data;

      const monthlyTotals = {};

      // Sumowanie kilometrów według miesięcy
      kilometersData.forEach((item) => {
        const date = new Date(item.date);
        const month = `${date.getFullYear()}-${date.getMonth() + 1}`; // Miesiąc w formacie yyyy-mm

        monthlyTotals[month] = (monthlyTotals[month] || 0) + item.km; // Sumowanie kilometrów
      });

      // Przygotowanie danych do wykresu
      const sortedMonths = Object.keys(monthlyTotals).sort();
      const totalData = sortedMonths.map((month) => monthlyTotals[month]);
      setLabels(sortedMonths);
      setData(totalData);
    } catch (error) {
      console.error("Error fetching kilometers:", error);
    }
  };

  useEffect(() => {
    fetchKilometers();
  }, []);

  return (
    <div>
      <StatisticsLineChart chartLabel={chartLabel} data={data} labels={labels} />
    </div>
  );
};

export default Stats;
