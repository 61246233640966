import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const UsersPage = (props) => {

    return (
        <div className="user-table">

        <h3>Użytkownicy</h3>

        <table className="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Użytkownik</th>
                    <th>Email</th>
                    <th>Test</th>
                    <th>Usuń</th>
                </tr>
            </thead>
            <tbody>
                {props.users.map((item, index) => {
                    return (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.user}</td>
                        <td>{item.email}</td>
                        <td><label>{item.test}</label></td>
                        <td><Link to={"./edytuj/" + item.id} className="btn">Edytuj</Link></td>
                    </tr>
                    )
                })}
            </tbody>
        </table>
        </div>
    )
}

export default UsersPage
