import React from 'react'
import { Line } from 'react-chartjs-2'

const StatisticsLineChart = (props) => {
    
    const data = {
        labels: props.labels,
        datasets: [
          {
            label: props.chartLabel,
            data: props.data,
            fill: true,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
          },
        ],
      };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    }

    

    return (
        <>
            <div style={{ width: '500px', height: '500px' }}>
                <Line data={data} options={options} />
            </div>
        </>
    )

}

export default StatisticsLineChart