import React from "react";
import { Link } from "react-router-dom";
import { Server, ShoppingCart, Briefcase, Mic, Settings } from "react-feather";
import "./home.css";

const SidebarData = [
  {
    id: "1",
    title: "Panel nawigacji",
    path: "/",
    icon: <Server color="#fff" size={17} strokeWidth={1.5} />,
    cName: "home-btns",
  },
  {
    id: "2",
    title: "Kilometry",
    path: "/kilometry/dodane",
    icon: <ShoppingCart color="#fff" size={17} strokeWidth={1.5} />,
    cName: "home-btns",
  },
  {
    id: "3",
    title: "Statystyki",
    path: "/worktime/",
    icon: <Briefcase color="#fff" size={17} strokeWidth={1.5} />,
    cName: "home-btns",
  },
  {
    id: "4",
    title: "Test",
    path: "/e-wizyta/",
    icon: <Mic color="#fff" size={17} strokeWidth={1.5} />,
    cName: "home-btns",
  },
  {
    id: "5",
    title: "Ustawienia",
    path: "/ustawienia/",
    icon: <Settings color="#fff" size={17} strokeWidth={1.5} />,
    cName: "home-btns",
  },
];

const Home = () => {
  return (
    <div>
      <div className="page-inside-content">
        <h2>Dzień dobry</h2>

        <div className="home-containers">
          {SidebarData.map((item, index) => {
            return (
              <div className="home-items" key={index}>
                <Link to={item.path} className={item.cName}>
                  <p className="sidebar-inside-btns-content">{item.title}</p>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
