import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./login.css";
import loginImg from "../../media/img/login.svg";
import Logo from "../../media/img/logo-kms.png";
import ForgotPasword from "../forgot-password/Forgot-pasword";
import Checkbox from "../checkbox/Checkbox";
import axios from "axios";

const Login = (props) => {
  const { isLogged, setIsLogged, setUser, isCheckboxIconOpen, setIsCheckboxIconOpen } = props;
  const [switchContent, setSwitchContent] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("http://192.168.1.7:5000/login", { username, password });
      const { token } = response.data;
      console.log("Token:", token); // Sprawdź, czy token jest poprawnie zwracany
      localStorage.setItem("token", token);
      localStorage.setItem("username", username);
      console.log("Username", username);
      setIsLogged(true);
      setUser(username);
      setError(null);
    } catch (err) {
      console.log("Błąd logowania:", err.response ? err.response.data : err.message);
      setError("Zły login lub hasło!");
    }
  };

  return (
    <section className="login">
      <div className="d-flex align-items-center container-login flex-column">
        <div className="container-logo">
          <Link to="/" className="logo">
            <img src={Logo} alt="" className="logo-img" />
          </Link>
        </div>
        <div className="container-form d-flex">
          {!switchContent ? (
            <>
              <form className="l-form" id="l-form" onSubmit={handleLogin}>
                <h4 className="l-text">Zaloguj się</h4>
                <input type="text" name="login" id="nick" className="l-input" placeholder="Login: " value={username} onChange={(e) => setUsername(e.target.value)} required />
                <div className="email-error"></div>
                <input type="password" id="l-password" name="password" className="l-input" placeholder="Hasło: " value={password} onChange={(e) => setPassword(e.target.value)} required />
                <div className="checkbox-container">
                  <Checkbox />
                  <label htmlFor="rememberMe" className="checkbox-container-text">
                    Pamiętaj mnie
                  </label>
                </div>
                <div className="password-error"></div>
                <input type="submit" className="s-input" value="Zaloguj się" />
                {error && <p className="login-error">{error}</p>}
                <p className="l-container-btn">
                  <a href="/#" className="l-btn" onClick={() => setSwitchContent(!switchContent)}>
                    Zapomniałeś loginu lub hasła?
                  </a>
                </p>
                <p className="l-container-btn">
                  <a href="/#" className="l-btn" onClick={() => setSwitchContent(!switchContent)}>
                    Nie masz konta? Zarejestruj się!
                  </a>
                </p>
              </form>
            </>
          ) : (
            <>
              <ForgotPasword switchContent={switchContent} setSwitchContent={setSwitchContent} />
            </>
          )}
          <div className="container-img">
            <img src={loginImg} alt="XD" className="l-img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
