import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebarInside.css";

const SidebarInside = (props) => {
  const { setPageContentSwitch, title, routes, currentPath } = props;
  const [activeRoute, setActiveRoute] = useState(1);
  const location = useLocation();

  useEffect(() => {
    const currentPathname = location.pathname.replace(currentPath, ""); // Zastępujemy bazowy path
    const activeItem = routes.find((item) => item.path === currentPathname);

    if (activeItem) {
      setActiveRoute(activeItem.id);
    }
  }, [location, routes, currentPath]);

  return (
    <div className="sidebar-inside">
      <div className="sidebar-inside-menu">
        {title}
        <ul className="sidebar-inside-btns-menu">
          {routes.map((item, index) => {
            const activeMenu = () => {
              setPageContentSwitch(item.page);
              setActiveRoute(item.id);
            };
            return (
              <li key={index}>
                <Link to={currentPath + item.path} onClick={activeMenu} className={activeRoute === item.id ? item.cName + " active" : item.cName}>
                  {item.icon}
                  <p className="sidebar-inside-btns-content">{item.title}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SidebarInside;
