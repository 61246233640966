import React from 'react'
import { useParams, Link } from 'react-router-dom'


const WorktimeEditUser = () => {
    const params = useParams();
    return (
        <div>
            <Link to="../uzytkownicy">powrut</Link>
            <div>klikłeś se użytnika o id: {params.id} </div>
        </div>
    )
}

export default WorktimeEditUser
