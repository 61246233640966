import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Server, ShoppingCart, Database, BarChart2, Settings, LogOut } from "react-feather";
import "./sidebar.css";
import SidebarLogo from "./SidebarLogo";
import HelloUser from "./HelloUser";

const Sidebar = (props) => {
  const { setIsLogged, setLoading, user } = props;

  const SidebarData = [
    {
      id: "1",
      title: "Panel nawigacji",
      path: "/",
      icon: <Server color="#fff" size={17} strokeWidth={1.5} />,
      cName: "sidebar-btns",
    },
    {
      id: "2",
      title: "Kilometry",
      path: "/kilometry/dodane",
      icon: <Database color="#fff" size={17} strokeWidth={1.5} />,
      cName: "sidebar-btns",
    },
    {
      id: "3",
      title: "Statystyki",
      path: "/statystyki/wszystkie",
      icon: <BarChart2 color="#fff" size={17} strokeWidth={1.5} />,
      cName: "sidebar-btns",
    },
    {
      id: "5",
      title: "Ustawienia",
      path: "/ustawienia/",
      icon: <Settings color="#fff" size={17} strokeWidth={1.5} />,
      cName: "sidebar-btns",
    },
  ];

  const [activeItemMenu, setActiveItemMenu] = useState("1"); // Domyślnie ustawiony na 1

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const activeItem = SidebarData.find((item) => item.path === currentPath);
    if (activeItem) {
      setActiveItemMenu(activeItem.id);
    }
  }, [location]);

  const handleLogout = () => {
    localStorage.removeItem("token"); // Usunięcie tokenu JWT
    setIsLogged(false); // Zmieniamy stan na wylogowany
  };

  return (
    <div className="sidebar">
      <SidebarLogo />
      <HelloUser user={user} />
      <div className="sidebar-menu">
        <h3 className="sidebar-menu-header">Wybierz opcje</h3>
        <ul className="sidebar-btns-menu">
          {SidebarData.map((item, index) => (
            <li key={index}>
              <Link to={item.path} onClick={() => setActiveItemMenu(item.id)} className={activeItemMenu === item.id ? item.cName + " active" : item.cName}>
                {item.icon}
                <p className="sidebar-btns-content">{item.title}</p>
              </Link>
            </li>
          ))}
          <li>
            <Link to="/" onClick={handleLogout} className="sidebar-btns">
              <LogOut color="#fff" size={17} strokeWidth={1.5} />
              <p className="sidebar-btns-content">Wyloguj się</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
